import {ApplicationContext} from "../../../context/Application";
import {Link, useNavigate} from "react-router-dom";
import "./sidebar.css";
import {MdDashboard} from "react-icons/md"
import {AiFillHome} from "react-icons/ai"
import {BiMessageAltDots} from  "react-icons/bi"
import {IoMdAdd} from "react-icons/io"
import {AiOutlineUser} from "react-icons/ai"
import {BsBuilding} from "react-icons/bs"
import {AiFillSetting} from "react-icons/ai"
import {FiLogOut} from "react-icons/fi"


const UserSideBar = () => {
    const {logout} = ApplicationContext();
     
    const redirect = useNavigate();



    
    return(
        <div id="main-user-sidebar-container">
             <aside>
                 <div className="top">
                     <div className="close"  id="close-btn">
                         <span className="material-icons-sharp"> close </span>
                     </div>
                 </div>
                 
                  <div className="sidebar">
                    <Link to="/user/dashboard" className="active">
                     <MdDashboard/>
                     <h3>Dashboard</h3>
                    </Link>
                    <Link to="/">
                     <AiFillHome/>
                     <h3>Home</h3>
                    </Link>
            
        
                    <Link to="/messages">
                     <BiMessageAltDots/>
                     <h3>Messages</h3><span className="message-count">35</span>
                    </Link>
                    <Link to="#">
                     <BsBuilding/>
                     <h3>Your Properties</h3>
                    </Link>
                    <Link to="#">
                     <AiOutlineUser/>
                     <h3>Profile</h3>
                    </Link>
                    <Link to="/upload-property">
                      <IoMdAdd/>
                       <h3>Add properties</h3>
                    </Link>
                    <Link to="#">
                     <AiFillSetting/>
                     <h3>Setting</h3>
                    </Link>

                    <Link to="/" onClick={()=>{ logout();redirect("/");}}>
                     <FiLogOut/>
                     <h3>Logout</h3>
                    </Link>
                  </div>
             </aside>
           {/* END OF ASIDE  */}          
        </div>
    )
}

export default UserSideBar;
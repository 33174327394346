import React,{useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {SideBar,Content,RightBar} from "../components/index.js"
import "./dashboard.css"
import {AiFillSetting} from "react-icons/ai"
import {ApplicationContext} from "../../context/Application"
import {SettingsContext} from '../../context/Settings';
import {Storage} from '../../helpers/auth'

//----------------------------------------------------------------------


const Dashboard = () => {
  let redirect = useNavigate();
  const {authUser} = ApplicationContext();
  const {SettingCores} = SettingsContext();


  useEffect(()=>{
   if(Storage.getAuthUser()){
       window.scrollTo(0, 0)
    }else{
      redirect("/login");
    } 
  },[authUser])


  return (
    <>
    <div id="dashboard">
      { <SideBar/> }
      <div>
         <Content/>  
      </div>
      <div>
        {/* <RightBar/> */}
      </div>

    </div>
    <div id="settings-button">
       <AiFillSetting/>
    </div>
    </>
  )
}

export default Dashboard
import React,{useState} from 'react'
import {MdMarkEmailUnread} from "react-icons/md";
import {Link} from "react-router-dom";
import { Footer } from '../../components';
import "./forgotpassword.css"
import Request from '../../helpers/request';
import { SettingsContext } from '../../context/Settings';
const ForgotPassword = () => {
    const {Settings} = SettingsContext();

    const [Data,setData] = useState({
        email:null
    });

    const [error,setError] = useState({
        emailError:null,
        appError:null
    })

    const [success,setSuccess] = useState(null);



    //Updating Signup Data
    let handleUpdate = (e) =>{
        setData({...Data,[e.target.name]:e.target.value})
     }


    const sentToServer = () => {
        Request.serverGetMethod(Settings.host.PATH+"/forgotpassword.php","?data="+JSON.stringify(Data), async(response,error)=>{
             if(response){
                 response  = JSON.parse(response);
                 if(response.status === "success" && response.statusCode === 200){
                   setSuccess(response.message);
                   setError({...error,appError:null});
                   setError({...error,emailError:null});
                 }else{
                    if(response.errors.emailError != null){
                      setError({...error,emailError:response.errors.emailError});
                      setSuccess(null);
                    }else{
                      setSuccess(null);
                      setError({...error,appError:response.errors.appError});
                    }
                 }
                console.log(response);
             }
        });
    }

  return (
    <div id='forgotpassword'>
       <div className="sign-form-container">

          <form className="sign-form">
             <h1 className="form-title">Recover Password</h1>
             {success && <div className = "success-message">
                  {success}
                  <p className="text-muted">
                     Please check your email.
                  </p>
                </div>}
              {
                error.appError && <div className = "error-message">
                    {error.appError}
                </div>
              }
             <h3 className="text-muted">Enter you email address below to get a password recovery email</h3>
          
              <div className="control-container flex-default space-between stretch-items">
                  <span className="form-icon-container flex-center transition200"><MdMarkEmailUnread/></span>
                  <label htmlFor="email" className="input-container">
                    <input type="text" className="input-control" placeholder="Email Address" name='email' onKeyUp={(e)=>{handleUpdate(e)}}  onChange={(e)=>{handleUpdate(e)}}/>
                  </label>
              </div>
             <span className="form-error-block errors">{error.emailError && error.emailError}</span>


             <div className="bottom-container">
                <p className="text-muted">Don't have an account? <Link to="/create-account" className='link'>Register</Link></p>
                <div>
                    <button className='btn btn-primary' type='button' onClick={()=>{sentToServer()}}>Send</button>
                </div>
             </div>              
          </form>
       </div>

       <Footer/>
    </div>
  )
}

export default ForgotPassword
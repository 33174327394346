
  import React, { useContext, useState,useEffect} from "react";
  import {Img3, Img4, Img5, Img6, Img7, Img8, Img9, Img10, Img11} from "../images/index.js";
  




  const GlobalContext = React.createContext();


/**
 * Global to all the application
 */
/* 
---------------------------------------------------------
  Functions That will be used throughout the application
---------------------------------------------------------
*/

const serverGetMethod = (url,dataToSend,callback) =>{
  let http = new XMLHttpRequest();
  let data =  dataToSend.length === 0 ? "" : dataToSend;
  http.open("GET",""+url+data);
  http.send();
  http.onreadystatechange = function(){
      
    if(http.status === 200 && http.readyState === 4 ){
      callback(http.responseText,null);
    }else{
      callback(null,"Error occured while processing data")
    }
  }
}




const serverPostMethod = (url,dataToSend,callback) =>{
 let http = new XMLHttpRequest();
 let data =  dataToSend.length === 0 ? "" : dataToSend;

 http.open("POST",""+url);
 http.send(data);

 http.onreadystatechange = function(){
   if(http.status === 200 && http.readyState === 4){
      callback(http.responseText,null);
   }else{
      callback(null,"Error occured while processing data")
   }
 }

}





/* 
----------------------------------------------------------
Gathering all the application context data
----------------------------------------------------------
*/
const ApplicationHelper = {
  Connectors:{
   serverGet:serverGetMethod,
   serverPost:serverPostMethod
 }
}




/* 
  ----------------------------------------------------------------
   The authenticated user Data start here : The authenticated user 
   is the user that is currently logged in.
  ----------------------------------------------------------------
*/
const user = {
  name:"",
  statusText:"offline",
  profile:"",
  status:0,
  post:[
    {
        productName: "Commercial house with serene evironment",
        discount:"20%",
        status:1,
        id: 1,         },
    {
        productName: "House in Abuja real estate",
        discount:"10%",
        status:1,
        id: 2,
    },
    {
        productName: "Mini Duplex for sell",
        discount:"0%",
        status:1,
        id: 3,
    },
    {
        productName: "Bungalow for sell - with exception",
        discount:"30%",
        status:"Due",
        id: 4,
    },
    {
        productName: "Commercial Offices",
        discount:"40%",
        status:1,
        id: 5,         
    }
],
}










/* 
  ----------------------------------------------------------------
    Agents Rating Data start here
  ----------------------------------------------------------------
*/

const agentRatings = [
    {
        agentId:4,
        useId:45,
        stars:4,
        content:"This agents is good",
        dateAdded: new Date(),
        
    },
]
/* 
  ----------------------------------------------------------------
    Agents Rating Data ends here
  ----------------------------------------------------------------
*/









/* 
------------------------------------------------------------------
    Home product start here
------------------------------------------------------------------
*/
const products = [
  {
    id:1,
    src:Img3,
    productName:"Office space available for rent",
  },
  {
    id:2,
    src:Img4,
    productName:"Clean and furnished Duplex in Maitama",
  },
  {
    id:3,
    src:Img5,
    productName:"The best bangalow in  abuja at a discounted price",
  },
  {
    id:4,
    src:Img6,
    productName:"Furnished bungallow with swimming pool",
  },
  {
    id:5,
    src:Img7,
    productName:"Computerized house with artificial intelligence",
  },
  {
    id:6,
    src:Img8,
    productName:"Commercial Residencial house with parking area",
  },
  {
    id:7,
    src:Img9,
    productName:"House for let at Kubwa express way",
  },
  {
    id:8,
    src:Img10,
    productName:"3 Story building for sell at jayi",
  },
  {
    id:9,
    src:Img8,
    productName:"Commercial Residencial house with parking area",
  },
];


/* 
------------------------------------------------------------------
    Home product end here
------------------------------------------------------------------
*/



/* 
  ----------------------------------------------------------------
    Trending products data start here
  ----------------------------------------------------------------
*/
const trendingProducts =   [{
   id:1,
   productName:"The best bangalow in  abuja at a discounted price",
   image:Img3,  
},
{
   id:2,
   productName:"Clean and furnished Duplex in Maitama",
   image:Img4,
},
{
   id:3,
   productName:"Office space available for rent",
   image:Img5,
}];



/* 
  ----------------------------------------------------------------
    Trending products end start here
  ----------------------------------------------------------------
*/








/*
 -----------------------------------------------------------------
   The Footer Data begins here
------------------------------------------------------------------
*/
  const footer = [
  {
    title: "Quick Links",
    text: [{ list: "Home",path:"" }, { list: "About", path:"about" }, { list: "Service",path:"services" }, { list: "Properties", path:"properties"}, { list: "Contact" ,path:"contact"}],
  },
  {
    title: "Explore",
    text: [{ list: "Features" }, { list: "Attractive" }, { list: "Testimonials" }, { list: "Videos" }],
  },
  {
    title: "COMPANY",
    text: [{ list: "About" }, { list: "Blog" }, { list: "Pricing" }, { list: "Affiliate" }, { list: "Login",path:"login" }, { list: "Register", path:"register" }],
  },
]

/*
 -----------------------------------------------------------------
   The Footer Data ends here
------------------------------------------------------------------
*/





/*
 -----------------------------------------------------------------
   The global context construction starts here
------------------------------------------------------------------
*/

export const Global = ({children}) =>{

  const [productType,setProductType] = useState(null)
 


  const cores = {products,footer}

 
   

    //Asign user array to auth_user
   const [authUser,setAuthUser] = useState(null);


  const logout = () => {
    updateUser(null);
    localStorage.removeItem('authUser');
  }

   const updateUser = (data = {}) =>{
      setAuthUser(JSON.parse(data));
   }
   

   

  useEffect(()=>{ 
      if(localStorage.getItem("authUser")){
        updateUser(localStorage.getItem("authUser"));
      }
  },[]);

  
      
  



  
   return(
    <GlobalContext.Provider value={{
          authUser,
          updateUser,
          logout,
          trendingProducts,
          productType,
          ApplicationHelper,
          cores
       }}>
        {children}     
     </GlobalContext.Provider>
   )
}

/*
 ----------------------------------------------------------------
   The global context construction ends here
-----------------------------------------------------------------
*/










/* 
------------------------------------------------------------------
  Exporting global context
------------------------------------------------------------------
 */
export const ApplicationContext = () => useContext(GlobalContext);


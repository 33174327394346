import React, { useEffect } from 'react'
import "./create-account.css"
import {Developer, User, Agent} from "../../images"
import {Footer} from "../../components"

import { useNavigate } from 'react-router-dom'

const CreateAccount = () => {
    const redirect = useNavigate();

    useEffect(()=>{
       window.scrollTo(0,0);
    },[])

  return (
    <section>
              <div id="create-account">
                   <div className="top">
                       <h1 className="heading">Register As</h1> 
                       <p className="text-muted">Choose the type of account you want to create</p>  
                   </div>          
            
                  <section className="account-wrapper">
                     <div className="box" onClick={()=>{redirect("/register/agent",{state:{occupation:"agent"}})}}>
                          <div className="image-container">
                              <img src={Agent} alt="" />
                          </div>
                          <div className="middle">
                             <h2>Agent Account</h2>
                             <p className="text-muted">
                                 Suitable for properties Agent
                             </p>
                          </div>
                          <div className="bottom">
                             <button className="btn">Get started</button>
                          </div>
                      </div>     

                     <div className="box" onClick={()=>{redirect("/register/developer",{state:{occupation:"developer"}})}}>
                          <div className="image-container">
                              <img src={Developer} alt="" />
                          </div>
                          <div className="middle">
                             <h2>Developer Account</h2>
                             <p className="text-muted">
                                 Suitable for companies who develops estate
                             </p>
                          </div>
                          <div className="bottom">
                             <button className="btn">Get Started</button>
                          </div>
                      </div>     
                     <div className="box" onClick={()=>{redirect("/register/user",{state:{occupation:"user"}})}}>
                          <div className="image-container">
                              <img src={User} alt="" />
                          </div>
                         <div className="middle">
                             <h2>User Account</h2>
                              <p className="text-muted">
                                  Suitable for those who are searching for a discounted properties to rent or purchase
                              </p>
                         </div>
                         <div className="bottom">
                            <button className="btn">Get Started</button>
                         </div>
                      </div>     
                  </section>    
          
              </div>
              <Footer/>
    </section>
  )
}


export default CreateAccount;

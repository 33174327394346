import React,{useState,useRef} from 'react'
import "./common.css"

const PriceSlider = () => {
    /* ===================================================================== */
    //Start price range with progress bar
     const minInput = useRef();
     const maxInput = useRef();
     const minRange = useRef();
     const maxRange = useRef();
     const progressBar = useRef();
     const priceGap = 1000;

     


    
  
  
    const [inputPrice,setInputPrice] = useState({
      minPrice:2500,
      maxPrice:10000
    });

    const handlePriceInput = (e) =>{
        setInputPrice({...inputPrice,[e.target.name]:e.target.value});
        let minPrice = parseInt(inputPrice.minPrice);
        let maxPrice = parseInt(inputPrice.maxPrice);
    
        if((maxPrice - minPrice >= priceGap) && maxPrice <= maxRange.current.max){
             if(e.target.className === "input-min"){
              minRange.current.value = minPrice;
              progressBar.current.style.left = ((minPrice/minRange.current.max) *100) + "%";
             }else{
              maxRange.current.value = maxPrice;
              progressBar.current.style.right = 100 - (maxPrice/maxRange.current.max) * 100 + "%";
             }
        }
      }



      const handleRange = (e) =>{
        setInputPrice({...inputPrice,[e.target.name]:e.target.value});
        let minVal = parseInt(minRange.current.value);
        let maxVal = parseInt(maxRange.current.value);
     
  
        if((maxVal - minVal) < priceGap){
  
          if(e.target.className === "range-min"){
             minInput.current.value = maxVal - priceGap;
          }else{
            maxInput.current.value = minVal + priceGap;
          }
        }else{
            minInput.current.value = minVal;
            maxInput.current.value = maxVal;
  
            progressBar.current.style.left = ((minVal / minRange.current.max) * 100) + "%";
            progressBar.current.style.right = 100 - (maxVal / maxRange.current.max) * 100 + "%";
        }
      }
      //End price range with progress bar
     /* ===================================================================== */
  return (
    
    <div>

          <div className="price-range-wrapper">
            
              <hgroup>
                 <h2 className='title'>Price Range (&#8358;)</h2>
                 <p className='text-muted'>Use slider or enter a min and max price</p>
              </hgroup>

              <div className="price-input">
                 <div className="field">
                    <span>Min</span>
                    <input type="number" className='input-min' defaultValue="30000000" name="minPrice" ref={minInput} onInput={(e)=>handlePriceInput(e)}/>
                 </div>
                 <div className="separator">-</div>
                 <div className="field">
                    <span>Max</span>
                    <input type="number" className='input-max' defaultValue="250000000" name='maxPrice' ref={maxInput} onInput={(e)=>handlePriceInput(e)}/>
                 </div>
              </div>


             <div className='slider-column'>
                 <div className="slider">
                     <div className="progress" ref={progressBar}></div>
                  </div>
    
                  <div className="range-input">
                     <input type="range" className="range-min" min="0" max="1000000000" defaultValue="30000000" step="100" name="minPrice" onChange={(e)=> handleRange(e)} ref={minRange}/>
                     <input type="range" className="range-max" min="0" max="1000000000" defaultValue="250000000" step="100" name="maxPrice" onChange={(e)=> handleRange(e)} ref={maxRange} />
                  </div>
             </div>


           </div>
    </div>
  )
}

export default PriceSlider
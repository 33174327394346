import React from "react"
import "./footer.css"
import { ApplicationContext } from "../../context/Application"
import { SettingsContext } from "../../context/Settings"
import {BiChevronRight} from "react-icons/bi"
import {Link} from "react-router-dom"

const Footer = () => {
  const {cores} = ApplicationContext();
  const {Settings} = SettingsContext();

  return (
    <footer id="main-footer">

      <section className='footerContact'>
        <div className='wrapper'>

          <div className='send flex space-between wrap-flex gap15'>
            <div className='text'>
              <h1>Do You Have Questions ?</h1>
              <p>We'll help you to grow your career and growth.</p>
            </div>
            <button className='btn'>Contact Us Today</button>
          </div>
        </div>
      </section>

      <section className="footer">

        <div className='footer-box-wrapper flex-center wrap-flex gap15 stretch-items'>

          <div className='box flex-center'>
            <div className='el'>
              <h2>Do You Need Help With Anything?</h2>
              <p>Receive updates, hot deals, tutorials, discounts sent straignt in your inbox every month</p>
                
             <form className='flex-center'>
                 <div className="column">
                     <input type='text' placeholder='Email Address' />
                     <button className="btn btn-primary" type="button">Subscribe</button>
                 </div>
              </form> 

              
            </div>
            
          </div>


          {cores.footer.map((val,indexes) => (
            <div className='box flex-center stretch-items' key={indexes}>
             
              <ul>
                <h3>{val.title}</h3>
                {val.text.map((items,index) => (
                  <li key={index} className="flex-center flex-start pointer">
                    <Link to={items.path !== undefined ? "/"+items.path : ""} className="flex stretch-items gap10">
                       <span className="flex-center"><BiChevronRight/></span> 
                       <span>{items.list}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            
            </div>
          ))}
        </div>
      </section>


      <div className='legal'>
        <span>{Settings.site.copyRightText}</span>
      </div>
    </footer>
  )
}

export default Footer

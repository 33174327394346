import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./propertyslider.css";
import {ApplicationContext} from '../../../context/Application';





function PropertySlider(props) {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display:  "block",borderRadius:"100%"}}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block",borderRadius:"100%" }}
        onClick={onClick}
      />
    );
  }

    var settings = {
        /* dots: true, */
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        dots: false,
         nextArrow: <SampleNextArrow />,
         prevArrow: <SamplePrevArrow />,
   /*      responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 8,
              slidesToScroll: 8,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1
            }
          }
        ] */
      };

      const {cores} = ApplicationContext()

    return (
        <div id='main-propertyslider'>
                <Slider {...settings}>
                    
                        {cores.products.map((items)=>{
                            return (<div className="slider-containers" key={items.id}>
                                <img src={items.src} alt={items.productName} />
                            </div>);
                        })} 
                   
                 </Slider>
        </div>
    );
}

export default PropertySlider;
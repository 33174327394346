import React from 'react'
import {PropertiesNav} from "../../components"
import PropertySlider from './propertyslider/PropertySlider'
import "./property.css";

const Property = () => {
  return (
    <div id='main-property-page'>
      {/* <PropertiesNav showAll={true}/> */}
      <section className="property-images-wrapper">
         <PropertySlider/>
      </section>
        
      <section className="right-container">
        
      </section>  
    </div>
  )
}

export default Property;
import React from 'react'
import "./sidebar.css"
import {FaUserTie} from "react-icons/fa"
import {FiLogOut} from "react-icons/fi"
import {MdDashboard} from "react-icons/md"
import {AiFillHome} from "react-icons/ai"
import {BiMessageAltDots} from  "react-icons/bi"
import {IoMdAdd} from "react-icons/io"
import {AiOutlineUser} from "react-icons/ai"
import {BsBuilding} from "react-icons/bs"
import {AiFillSetting} from "react-icons/ai"
import {AiOutlineClose} from "react-icons/ai"
import {MdRealEstateAgent} from "react-icons/md"
import {MdLogin} from "react-icons/md"
import {FaBookReader} from "react-icons/fa"
import {AiOutlineUnorderedList} from "react-icons/ai"
import {GiArchiveRegister} from "react-icons/gi"
import {BiMessageAltEdit} from "react-icons/bi"
import {Link, useNavigate} from "react-router-dom";
import { ApplicationContext } from '../../context/Application'
import { SettingsContext } from '../../context/Settings';
import {truncator} from "../../helpers/util";




const Sidebar = (props) => {
  const {authUser,logout} = ApplicationContext();
  const redirect = useNavigate();
  const {SettingCores} = SettingsContext();
  const {openSidebar, setOpenSidebar,activePage,setActivePage} = SettingCores;



  const name = authUser ? authUser.firstname : "";
  const profile = "";



  return (
    <div  className={props.open ? "sidebar-wrapper open" : "sidebar-wrapper"}>
        <span className="flex-center close dim-3rem circle" onClick={()=>{setOpenSidebar(!openSidebar)}}><AiOutlineClose/> </span>

       {authUser !== null && <div className="top">
          
            <div className="user-box flex-center gap15">
                 <div className="user-box-img dim-3rem circle flex-center">
                   { profile ? <img src={profile} alt={authUser.name}/> : <FaUserTie className='user-icon'/>}
                 </div>

                 <div className="user-box-details">
                      <h2 className="user-box-name">{name}</h2>
                      <p className="text-muted account-type">Agent</p>
                 </div>
            </div>

        </div>}


        <div className="middle">
          {authUser !== null &&  <> <Link className='link-box' to="/user/dashboard" onClick={()=>{setOpenSidebar(!openSidebar)}}>
             <MdDashboard/>
             <h3>Dashboard</h3>
            </Link>
          
            <Link className='link-box' to="/messages" onClick={()=>{setOpenSidebar(!openSidebar)}}>
             <BiMessageAltDots/>
             <h3>Messages</h3><span className="message-count">35</span>
            </Link>
            <Link className='link-box' to="#" onClick={()=>{setOpenSidebar(!openSidebar)}}>
             <BsBuilding/>
             <h3>Your Properties</h3>
            </Link>
            <Link className='link-box' to="#" onClick={()=>{setOpenSidebar(!openSidebar)}}>
             <AiOutlineUser/>
             <h3>Profile</h3>
            </Link>
            <Link className='link-box' to="/upload-property" onClick={()=>{setOpenSidebar(!openSidebar)}}>
              <IoMdAdd/>
               <h3>Add properties</h3>
            </Link>
            <Link className='link-box' to="#" onClick={()=>{setOpenSidebar(!openSidebar)}}>
             <AiFillSetting/>
             <h3>Setting</h3>
            </Link> </>}

            <h2 className="title">Explore</h2>
            <Link className='link-box' to="/" onClick={()=>{setOpenSidebar(!openSidebar)}}>
             <AiFillHome/>
             <h3>Home</h3>
            </Link>
            <Link className='link-box' to="/properties" onClick={()=>{setOpenSidebar(!openSidebar)}}>
             <AiOutlineUnorderedList/>
             <h3>Listing</h3>
            </Link>
            <Link className='link-box' to="/about" onClick={()=>{setOpenSidebar(!openSidebar)}}>
             <FaBookReader/>
             <h3>About</h3>
            </Link>
            <Link className='link-box' to="/agents" onClick={()=>{setOpenSidebar(!openSidebar)}}>
             <MdRealEstateAgent/>
             <h3>Agents</h3>
            </Link>
            <Link className='link-box' to="/contact" onClick={()=>{setOpenSidebar(!openSidebar)}}>
             <BiMessageAltEdit/>
             <h3>Contact</h3>
            </Link>
           { !authUser && <><Link className='link-box' to="/login" onClick={()=>{setOpenSidebar(!openSidebar)}}>
             <MdLogin/>
             <h3>Login</h3>
            </Link>
            <Link className='link-box' to="/create-account" onClick={()=>{setOpenSidebar(!openSidebar)}}>
             <GiArchiveRegister/>
             <h3>Sign Up</h3>
            </Link></>}


        </div>



       { authUser && <div className="bottom">
           <h3 className="logout-box flex-center gap15" onClick={()=>{ logout();redirect("/");setOpenSidebar(!openSidebar)}}>
               <span className='flex-center'><FiLogOut/></span> Logout
           </h3>
       </div>}
    </div>
  )
}

export default Sidebar
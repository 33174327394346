import React,{ useState,useEffect,useRef} from 'react'
import {Link, useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import "./register.css";
import {MdMarkEmailUnread} from "react-icons/md";
import {RiLockPasswordLine} from "react-icons/ri";
import {MdPassword} from "react-icons/md";
import {Footer} from "../../components";
import { SettingsContext } from '../../context/Settings';
import {ApplicationContext} from "../../context/Application"
import {Storage} from "../../helpers/auth"
import Request from "../../helpers/request"
import {BiUserPlus} from "react-icons/bi"

let Register = ({classes = ""}) => {
  //Initializing useNavigate
  let navigate = useNavigate();
  const locationData = useLocation()
  const [occupation , setOccupation] = useState("agent")

    /* 
      ---------------------------------------------------------------------
                    VALIDATION START
      ---------------------------------------------------------------------
    */

      const [signupData,setSignupData] = useState({
        firstname:"",
        lastname:"",
        email:"",
        password:"",
        accounttype: "",
        confirmPassword:""
      });
  
  
      const [errors,setErrors] =  useState({
          firstnameError:null,
          lastnameError:null,
          emailError:null,
          passwordError:null,
          confirmPasswordError:null
      });


  
  useEffect(() => {
    if(Storage.getAuthUser()){
      navigate("/");
    }else{
       window.scrollTo(0, 0);
       if(locationData.state){
         setOccupation(locationData.state.occupation);
         setSignupData({...signupData,accounttype:locationData.state.occupation});
       }
     } 
  }, [])




  

   const {ApplicationHelper} = ApplicationContext();
   const {Settings,SettingCores} = SettingsContext();

   


 




    //Creating References to input field
    let email = useRef();
    let password = useRef();
    let confirmPassword = useRef();
    let firstname =  useRef();
    let firstnamePlaceholder = useRef();
    let lastname = useRef();
    let lastnamePlaceholder = useRef();




    /* 
      ---------------------------------------------------------------------
                    CUSTOM PLACEHOLDER START
      ---------------------------------------------------------------------
    */
    //Placeholders Referencesc
    let emailPlaceholder = useRef();
    let passwordPlaceholder = useRef();
    let confirmPasswordPlaceholder = useRef();


    let placeholderSwitch = (inputRef,placeholderRef)=>{
      if(inputRef.current.value.length !== 0){
        placeholderRef.current.style.top = "-13px";
        placeholderRef.current.style.background = "#f1f1ff";
        
        placeholderRef.current.style.color = "#396aff";
      }else{
        placeholderRef.current.style.top = "7px";
        placeholderRef.current.style.color = "#888";
        placeholderRef.current.style.background = "transparent";
      }
    }

  /* 
     ---------------------------------------------------------------------
                    CUSTOM PLACEHOLDER END
     ---------------------------------------------------------------------
  */






     const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
     );


 



    //Updating Signup Data
    let handleUpdate = (e) =>{
       setSignupData({...signupData,[e.target.name]:e.target.value})
    }
  


    let validEmail = () =>{
      if(validEmailRegex.test(signupData.email)){
        setErrors({...errors,emailError:null});
        return true;
      }else{
        setErrors({...errors,emailError:"Please enter a valid email address"});
        return false;
      }
    }



    
    let checkPasswordMatch = () =>{
      if(signupData.password !== null){
         if(signupData.password === signupData.confirmPassword){
            setErrors({...errors, confirmPasswordError:null});
            return true;
         }else{
            setErrors({...errors, confirmPasswordError:"Password did not match"});
            return false;
         }
      }
    }

     
    let validator = (key) =>{
       if(key === "firstname"){
        signupData.firstname !== null && signupData.firstname.trim() === "" ?  setErrors({...errors,firstnameError:"First name cannot be empty"}) 
           : setErrors({...errors,firstnameError:null});
       }
       if(key === "lastname"){
          signupData.lastname !== null && signupData.lastname.trim() === "" ?  setErrors({...errors,lastnameError:"Last name cannot be empty"}) 
           : setErrors({...errors,lastnameError:null});
       }
       if(key === "email"){
          signupData.email !== null && signupData.email.trim() === "" ?  setErrors({...errors,emailError:"Please enter your email address"}) 
           : setErrors({...errors,emailError:null});
       }
       if(key === "password"){
          signupData.password !== null && signupData.password === "" ?  setErrors({...errors,passwordError:"Please create a password"}) 
           : setErrors({...errors,passwordError:null});
       }
       if(key === "confirmPassword"){
          signupData.confirmPassword !== null && signupData.confirmPassword === "" ?  setErrors({...errors,confirmPasswordError:"Please confirm the password you typed above"}) 
           : setErrors({...errors,confirmPasswordError:null});
       }
    }
   




    //This function will upload signup data to the server will 
    let sendDataToServer = () =>{
      if(signupData.firstname.trim() !== ""){
        if(signupData.lastname.trim() !== ""){
          if(signupData.email.trim() !== ""){
            if(validEmail()){
                 if(signupData.password.length >0){
                        
                        if(checkPasswordMatch()){
                          if(errors.firstnameError ===null &&
                            errors.lastnameError === null &&
                            errors.emailError === null &&
                            errors.passwordError === null,
                            errors.confirmPasswordError === null){
                              Request.serverGetMethod(Settings.host.PATH+"/insertuser.php?data=",JSON.stringify(signupData),async(response,error)=>{
                                
                               if(response){

                                 response  = JSON.parse(response);
                                     if(response.status === "success" && response.statusCode === 200){
                                          navigate("/login");
                                     }else{
                                         setErrors({...errors,firstnameError:response.errors.firstnameError,
                                           lastnameError:response.errors.lastnameError,
                                           emailError:response.errors.emailError,
                                           passwordError:response.errors.passwordError,
                                         });
                                     }
                               }
                              });
                              
                   
                         }
                        }

                 }else{
                  validator("password");
                 } 
            }
           
          }else{
            validator("email");
          }
           
        }else{
          validator("lastname");
        }  
      }else{
        validator("firstname");
      }
       
    }





  
   


    //Final data and errors
  /*   console.log(signupData);
    console.log(errors) */



    const updateOccupation = (d) => {
      setOccupation(d);
      setSignupData({...signupData,accounttype:d});
  }



  
  
  return (
    <div id='register-page'>
          
          <section className="sign-form-container">
             
             {/* <div className="container flex-center">
              <div>
                <h1>Buy, Sell And Rent Properties at Ease</h1>
                <h3>Hi..Register to become part of our community</h3>
                <h4>Already have an account? <Link to="/login">Sign In</Link></h4>
              </div>    
             </div> */}

            <form className="sign-form">
          
                <div>
                   <h1 className="form-title">Register</h1>
                    <p className="text-muted">Already have an account? <Link to="/login" className='link'>Sign In</Link></p>
                        
                        <div className="top-wrapper flex-center gap10">
                            <button className={occupation === "agent" ? "accupation btn" : "btn"} type="button" onClick={()=>{updateOccupation("agent");navigate("/register/agent")}}>Agent</button>
                            <button className={occupation === "developer" ? "accupation btn" : "btn"} type="button" onClick={()=>{updateOccupation("developer");navigate("/register/developer")}}>Developer</button>
                            <button className={occupation === "user" ? "accupation btn" : "btn"} type="button" onClick={()=>{updateOccupation("user");navigate("/register/user")}}>User</button>
                        </div>

                         <div className="row flex-default">
                             <div className="control-container flex-default stretch-items">
                                      <span className="form-icon-container flex-center transition200"><MdMarkEmailUnread/></span>
                               
                                      <label htmlFor="email" className="input-container">
                                        <input type="text"  name='firstname' id="firstname"
                                         onChange={(e)=>{validator("firstname");handleUpdate(e);placeholderSwitch(firstname,firstnamePlaceholder)}}  ref={firstname} onKeyUp={(e)=>{validator("firstname");handleUpdate(e);placeholderSwitch(firstname,firstnamePlaceholder)}}  
                                         onClick={()=>{placeholderSwitch(firstname,firstnamePlaceholder)}}/>
                                        <span className="placeholder-text transition300" ref={firstnamePlaceholder}>First Name</span>
                                     </label>
                             </div>
                          
    
                               <div className="control-container flex-default stretch-items">
                                      <span className="form-icon-container flex-center transition200"><MdMarkEmailUnread/></span>
                               
                                      <label htmlFor="lastname" className="input-container">
                                        <input type="text"  name='lastname' id="lastname"
                                         onChange={(e)=>{validator("lastname");handleUpdate(e);placeholderSwitch(lastname,lastnamePlaceholder)}}  ref={lastname} onKeyUp={(e)=>{validator("lastname");handleUpdate(e);placeholderSwitch(lastname,lastnamePlaceholder)}}  
                                         onClick={()=>{placeholderSwitch(lastname,lastnamePlaceholder)}} 
                                        />
                                        <span className="placeholder-text transition300" ref={lastnamePlaceholder}>Last Name</span>
                                     </label>
                               </div>
                         </div>
                         <span className="form-error-block errors">
                           <div>{errors.firstnameError !== null && errors.firstnameError}</div>
                           <div>{errors.lastnameError !== null && errors.lastnameError}</div>
                         </span>
                      


    
                         <div className="control-container flex-default stretch-items">
                                <span className="form-icon-container flex-center transition200"><MdMarkEmailUnread/></span>
                         
                                <label htmlFor="email" className="input-container">
                                  <input type="email"  name='email'
                                   onChange={(e)=>{handleUpdate(e);placeholderSwitch(email,emailPlaceholder)}}  ref={email} onKeyUp={(e)=>{validator("email");handleUpdate(e);placeholderSwitch(email,emailPlaceholder)}}  
                                   onClick={()=>{placeholderSwitch(email,emailPlaceholder)}}/>
                                  <span className="placeholder-text transition300" ref={emailPlaceholder}>Email Address</span>
                               </label>
                          </div>
                          <span className="form-error-block errors">{errors.emailError !== null && errors.emailError}</span>
                      


                         {/* <div className="control-container flex-default space-between stretch-items">
                                <span className="form-icon-container flex-center transition200"><MdMarkEmailUnread/></span>
                         
                                <label htmlFor="phone" className="input-container">
                                  <input type="tel"  name='phone'
                                   onChange={(e)=>{handleUpdate(e);placeholderSwitch(email,emailPlaceholder)}}  ref={email} onKeyUp={(e)=>{validator("email");handleUpdate(e);placeholderSwitch(email,emailPlaceholder)}}  
                                   onClick={()=>{placeholderSwitch(email,emailPlaceholder)}}/>
                                  <span className="placeholder-text transition300" ref={emailPlaceholder}>Email Address</span>
                               </label>
                          </div>
                          <span className="form-error-block errors">{errors.emailError !== null && errors.emailError}</span>
                       */}
     

         
                  
                          <div className="control-container flex-default stretch-items">
                             <span className="form-icon-container flex-center transition200"><RiLockPasswordLine/></span>
                             <label htmlFor="password" className="input-container">
                               <input type="password" name='password' autoComplete="off"
                                ref={password} onKeyUp={(e)=>{validator("password");handleUpdate(e);placeholderSwitch(password,passwordPlaceholder)}}
                               onClick={()=>{placeholderSwitch(password,passwordPlaceholder)}}
                               onChange={(e)=>{handleUpdate(e);placeholderSwitch(password,passwordPlaceholder);}}
                               onKeyDown={()=>validator("password")}/>
                                 <span className="placeholder-text transition300" ref={passwordPlaceholder}>Create Password</span>
                            </label>
                          </div>
                          <span className="form-error-block errors">{errors.passwordError !== null && errors.passwordError}</span>
                        


                          <div className="control-container flex-default stretch-items">
                             <span className="form-icon-container flex-center transition200"><MdPassword/></span>
                             <label htmlFor="confirmPassword" className="input-container">
                               <input type="password" name='confirmPassword' id="confirmPassword" autoComplete="off" 
                               ref={confirmPassword} onKeyUp={(e)=>{validator("confirmPassword");handleUpdate(e);placeholderSwitch(confirmPassword,confirmPasswordPlaceholder)}}
                               onClick={()=>{placeholderSwitch(confirmPassword,confirmPasswordPlaceholder)}}
                               onChange={(e)=>{validator("confirmPassword");handleUpdate(e);placeholderSwitch(confirmPassword,confirmPasswordPlaceholder);}}
                               onKeyDown={()=>validator("confirmPassword")}/>
                               <span className="placeholder-text transition300" ref={confirmPasswordPlaceholder}>Confirm Password</span>
                             </label>
                          </div>
                          <span className="form-error-block errors">{errors.confirmPasswordError !== null && errors.confirmPasswordError}</span>
                          
                          
                  
                          <div className="bottom-container">
                            <p className="text-muted">By clicking register, you agree to our terms and conditions</p>
                           <div className='flex-center'>
                             <button type="button" onClick={()=>{sendDataToServer()}} className="btn btn-primary" value="Create Account"> <span style={{paddingRight:"1rem"}} className="flex-center"><BiUserPlus/> </span> Create Account </button>
                           </div>
                         </div>
                   </div>
            </form>

          </section>
          <Footer/>
    </div>
  )
}


export default Register;


import React,{useContext,useState} from "react";


const SettingContext  = React.createContext();






const Settings = {
    site:{
        logo:"",
        motto:"",
        copyRightText:"All right reserved  Torbita | 2022",
      },
      theme:{
          ligth:"Light",
          dark:"Dark",
      },
      user:{
        loggedIn:false,
      },
      host:{
          //PATH:"http://localhost/estate", //Test Server
          PATH:"http://api.affordableproperty.com.ng/estate",//Live path
      }
}







export const Setting = ({children}) =>{

    const [openRegister,setOpenRegister] = useState(false);
    const openRegisterForm = () =>{
        setOpenRegister(!openRegister);
    }



    const [openLogin,setOpenLogin] = useState(false);
    const openLoginForm =()=>{
        setOpenLogin(!openLogin);
    }

   
    const [openSidebar,setOpenSidebar] = useState(false);
    const [activePage,setActivePage] = useState("home");




   //Cores settings
   const SettingCores = {openRegister, openRegisterForm, openLogin,openLoginForm,
                         openSidebar,setOpenSidebar,activePage,setActivePage};

    return(
      <SettingContext.Provider value={{Settings,SettingCores}}>
        {children}
     </SettingContext.Provider>
    )



}

export const SettingsContext = () => useContext(SettingContext)
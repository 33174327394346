import {ApplicationContext} from "../../../context/Application"
import "./content.css";
import{DeleteProductButton, UpdateProductButton} from "../../../components/index"
import {BiDotsHorizontalRounded} from "react-icons/bi"
import {BsBuilding} from "react-icons/bs"
import {MdAnalytics} from "react-icons/md"
import {AiOutlineFundProjectionScreen} from "react-icons/ai"
import { truncator } from "../../../helpers/util";
const Content = () =>{
    const {authUser} = ApplicationContext();
    const name = authUser ? authUser.firstname : "";
    return(
        <div id="main-user-content-container">
            <main>
                <h1>Dashboard</h1>
               <h2>Hi.. <span className="color-primary">{truncator(name,10)+".."}</span></h2>
                <p className="text-muted">This is your dashboard</p>
               <div className="insights">
                   <div className="sales">
                       <span className="flex-center box-icon dim-3rem">
                         <BsBuilding />
                       </span>
                       <div className="middle">
                           <div className="left">
                               <h3>Listed Properties</h3>
                               <h1>4</h1>
                           </div>
                           <div className="progress">
                               <svg>
                                  <circle cx="38" cy="38" r="36"></circle>
                               </svg>
                               <div className="number">
                                   <p>81%</p>
                               </div>
                           </div>
                       </div>
                       <small className="text-muted">Last 24 Hours</small>
                   </div>
                {/*   END OF SALES  */}
    

                   <div className="expenses">
                      <span className="flex-center box-icon dim-3rem">
                       <MdAnalytics/>
                      </span>
                       <div className="middle">
                           <div className="left">
                               <h3>Engagement</h3>
                               <h1>1,024</h1>
                           </div>
                           <div className="progress">
                               <svg> 
                                  <circle cx="38" cy="38" r="36"></circle>
                               </svg>
                               <div className="number">
                                   <p>56%</p>
                               </div>
                           </div>
                       </div>
                       <small className="text-muted">Last 24 Hours</small>
                   </div>
                   {/* END OF EXPENSES  */}


                   <div className="income">
                     <span className="flex-center box-icon dim-3rem">
                       <AiOutlineFundProjectionScreen/>
                     </span>
                       <div className="middle">
                           <div className="left">
                               <h3>Promoted Properties</h3>
                               <h1>3</h1>
                           </div>
                           <div className="progress">
                               <svg>
                                  <circle cx="38" cy="38" r="36"></circle>
                               </svg>
                               <div className="number">
                                   <p>81%</p>
                               </div>
                           </div>
                       </div>
                       <small className="text-muted">Last 24 Hours</small>
                   </div>
                   {/* -- END OF INCOME -- */}
               </div>
               {/* -------------------- END OF INSIGHTS ------------------ */}
               

               <div className="recent-orders">
                   <h2>Published Pruducts</h2>
                   <table>
                       <thead>
                           <tr>
                               <th>Product Name</th>
                               <th>Status</th>
                               <th>Discount</th>
                               <th>Actions</th>
                           </tr>
                       </thead>
                       <tbody>
                {/* 
                         {authUser.post && authUser.post.map((items,index)=>{
                            return(
                                <tr key={index}>
                                   <td>{items.productName}</td>
                                   <td>{items.status == 1 ? "Active":"Offline"}</td>
                                   <td>{items.discount}</td>
                                   <td>
                                     <div className="flex">
                                        <span>
                                            <DeleteProductButton/>
                                        </span>
                                        <span>
                                            <UpdateProductButton/>
                                        </span>
                                        <span>
                                            <BiDotsHorizontalRounded/>
                                        </span>
                                     </div>
                                   </td>
                                </tr>
                            )
                         })} */}
                       </tbody>
                   </table>
                   <a href="#">Show All</a>                
               </div>
            </main>
        </div>
    )
}


export default Content;
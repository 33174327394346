import React,{useState,useEffect} from 'react'
import "./css/style.filter.css";
import {FiChevronDown} from "react-icons/fi"
import { Link } from 'react-router-dom';
import { useRef } from 'react';
import {BsPlus} from "react-icons/bs"
import {BiDotsHorizontalRounded} from "react-icons/bi"



const PropertiesFilterBar = (props) => {

   const [businessType,setBusinessType] = useState("sale");

/* ===================================================================== */
  //Start price range with progress bar
   const minInput = useRef();
   const maxInput = useRef();
   const minRange = useRef();
   const maxRange = useRef();
   const progressBar = useRef();
   const priceGap = 1000;
   /* Property size */
   const minSizeInput = useRef();
   const maxSizeInput = useRef();
   const progress = useRef();



  const [inputPrice,setInputPrice] = useState({
    minPrice:2500,
    maxPrice:10000
  });


  /* Propery Size State */
  const [size,setPropertySize] = useState({
    minSize:2500,
    maxSize:10000
  })

   const handlePropertySizeRange = (e) =>{
      setPropertySize({...size,[e.target.name]:e.target.value});

      let minVal = parseInt(minSizeInput.current.value);
      let maxVal = parseInt(maxSizeInput.current.value);
   
      progress.current.style.left = ((minVal / minSizeInput.current.max) * 100) + "%";
      progress.current.style.right = 100 - (maxVal / maxSizeInput.current.max) * 100 + "%";
    
   }



  const handlePriceInput = (e) =>{
    setInputPrice({...inputPrice,[e.target.name]:e.target.value});
    let minPrice = parseInt(inputPrice.minPrice);
    let maxPrice = parseInt(inputPrice.maxPrice);

    if((maxPrice - minPrice >= priceGap) && maxPrice <= maxRange.current.max){
         if(e.target.className === "input-min"){
          minRange.current.value = minPrice;
          progressBar.current.style.left = ((minPrice/minRange.current.max) *100) + "%";
         }else{
          maxRange.current.value = maxPrice;
          progressBar.current.style.right = 100 - (maxPrice/maxRange.current.max) * 100 + "%";
         }
    }
  }

  const handleRange = (e) =>{
      setInputPrice({...inputPrice,[e.target.name]:e.target.value});
      let minVal = parseInt(minRange.current.value);
      let maxVal = parseInt(maxRange.current.value);
   

      if((maxVal - minVal) < priceGap){

        if(e.target.className === "range-min"){
           minInput.current.value = maxVal - priceGap;
        }else{
          maxInput.current.value = minVal + priceGap;
        }
      }else{
          minInput.current.value = minVal;
          maxInput.current.value = maxVal;

          progressBar.current.style.left = ((minVal / minRange.current.max) * 100) + "%";
          progressBar.current.style.right = 100 - (maxVal / maxRange.current.max) * 100 + "%";
      }
    }
    //End price range with progress bar
   /* ===================================================================== */



   /**
    * 
    * Gathering filter data
    */
   const filterData = {
     minPrice:inputPrice.minPrice,
     maxPrice:inputPrice.maxPrice,
     minSize:size.minSize,
     maxSize:size.maxSize
   }


   console.log(filterData);




  return (
    <section id="main-properties-filter-bar" className={props.className}>

         
      <div className="business-type flex-center gap20">
         <button type='button' className={businessType === "sale" ? "btn btn-primary" :"btn border"} onClick={()=>setBusinessType("sale")}>Sale</button>
         <button type='button' className={businessType === "rent" ? "btn btn-primary" : "btn border"} onClick={()=>setBusinessType("rent")}>Rent</button>
      </div>
       



       <div className="top">
            <div className="row flex">
              <h2 className="title">Property Category</h2>
              <span className="flex">
                 <FiChevronDown/>
              </span>
            </div>
            <div className="type-buttons-container flex gap15">
                <button className="type-button button-active">Offices Space</button>
                <button className="type-button">Bungalow</button>
                <button className="type-button">Whole Building</button>
                <button className="type-button">Homes</button>
            </div>
            <div className="other-category">
                <h2 className='title'>Other Categories</h2>
                <select name="" id="category" className="control transition300">
                   <option defaultValue="null"> Property Category</option>
                   <option value="Land">Land</option>
                   <option value="duplex">Duplex</option>
                </select>
            </div>
       </div>

       <div className="middle">

           <div className="price-range-wrapper">
              <hgroup>
                 <h2 className='title'>Price Range</h2>
                 <p>Use slider or enter a min and max price</p>
              </hgroup>

              <div className="price-input">
                 <div className="field">
                    <span>Min</span>
                    <input type="number" className='input-min' defaultValue="30000" name="minPrice" ref={minInput} onInput={(e)=>handlePriceInput(e)}/>
                 </div>
                 <div className="separator">-</div>
                 <div className="field">
                    <span>Max</span>
                    <input type="number" className='input-max' defaultValue="250000000" name='maxPrice' ref={maxInput} onInput={(e)=>handlePriceInput(e)}/>
                 </div>
              </div>

              <div className="slider">
                 <div className="progress" ref={progressBar}></div>
              </div>

              <div className="range-input">
                 <input type="range" className="range-min" min="0" max="1000000000" defaultValue="30000" step="100" name="minPrice" onChange={(e)=> handleRange(e)} ref={minRange}/>
                 <input type="range" className="range-max" min="0" max="1000000000" defaultValue="250000000" step="100" name="maxPrice" onChange={(e)=> handleRange(e)} ref={maxRange} />
              </div>
           </div>

            <div className="rooms-container">
                <h2 className='title'>Bed Rooms</h2>
                <div className="row flex gap10">
                  <label htmlFor="" className="room-number flex-center transition300 button-active">Any</label>
                  <label htmlFor="" className="room-number flex-center transition300">1</label>
                  <label htmlFor="" className="room-number flex-center transition300">2</label>
                  <label htmlFor="" className="room-number flex-center transition300">3</label>
                  <label htmlFor="" className="room-number add flex-center transition300 dropdown">4<BsPlus/> <span className='menu'><BiDotsHorizontalRounded/></span></label>
                </div>
            </div>

            <div className="furnish-container">
              <h2 className="title">Furnishing</h2>
              <div className="row flex gap10">
                  <label htmlFor='' className='furnish flex-center transition300'>All</label>
                  <label htmlFor='' className='furnish flex-center transition300'>Unfurnished</label>
                  <label htmlFor='' className='furnish flex-center transition300'>Furnished</label>
              </div>
            </div>



            <div className="property-size-container">
              <hgroup className="flex">
                 <h2 className="title">Property Size</h2>
                 <h3>More 66000 sqft</h3>
              </hgroup>
              <div className="result-container flex">
                 <p className="result">{size.minSize}sqft</p>
                 -
                 <p className="result">{size.maxSize}sqft</p>
              </div>
              <div className="slider">
                   <div className="progress" ref={progress}></div>
                </div>
               <div className="range-input">
                    <input type="range" className="range-min" min="0" max="20000" defaultValue="2500" step="100" name="minSize" onChange={(e)=> handlePropertySizeRange(e)} ref={minSizeInput}/>
                    <input type="range" className="range-max" min="0" max="20000" defaultValue="7500" step="100" name="maxSize" onChange={(e)=> handlePropertySizeRange(e)} ref={maxSizeInput} />
                 </div>
            </div>
       </div>



       <div className="bottom flex-center">
           <div className="button-container flex-center">
             <button className='btn btn-primary'>Done</button>
           </div>
       </div>
    </section>
  )
}

export default PropertiesFilterBar
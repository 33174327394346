import React from 'react'

const LoadmoreBtn = (props) => {
  return (
    <div className='flex-center' style={{padding:"2rem 1rem"}}>
        <button className='btn btn-primary' style={{borderRadius:"31px"}} onClick={props.events}>Load More</button>
    </div>
  )
}

export default LoadmoreBtn
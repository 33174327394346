export const isEmpty = (obj) => {
    for(var prop in obj) {
        if(obj.hasOwnProperty(prop))
            return false;
    }
    return true;
}


export const truncator = (str="",len = 70) =>{
    let result = str.substring(0,len);
    return result;
}



import React from 'react'
import "./contact.css"
import {FiSend} from "react-icons/fi"
import {BsTelephonePlus} from "react-icons/bs"
import {MdOutlineMail} from "react-icons/md"
import {GoLocation} from "react-icons/go"
import {AiFillFacebook} from "react-icons/ai"
import {AiFillTwitterSquare} from "react-icons/ai"
import {AiFillInstagram}  from "react-icons/ai"
import {FaWhatsappSquare} from "react-icons/fa"

const Contact = () => {
  return (
    <section id="contact-page">
        <div className="wrapper flex stretch-items gap15">
            <div className="column">
               <div className="top">
                  <h1 className="title">Get in touch</h1>
                  <p className="text">
                    Fill out this form and we will be in touch with you soon
                  </p>
               </div>

               <div className="middle">
                  <ul>
                    <li className="flex"><span className='flex-center'><BsTelephonePlus/></span> +234 806 275 1729</li>
                    <li className="flex"><span className='flex-center'><MdOutlineMail/></span>info@ affordableproperty.com.ng</li>
                    <li className="flex"><span className='flex-center'><GoLocation/></span>Abuja, Nigeria</li>
                  </ul>
               </div>

               <div className="bottom">
                  <div className="social-btns flex gap10">
                      <span className="flex-center circle dim-3rem transition300"><AiFillFacebook/></span>
                      <span className="flex-center circle dim-3rem transition300"><AiFillTwitterSquare/></span>
                      <span className="flex-center circle dim-3rem transition300"><AiFillInstagram/></span>
                      <span className="flex-center circle dim-3rem transition300"><FaWhatsappSquare/></span>
                  </div>
               </div>
            </div>
            <div className="column">
                 <form action="">
                    <div className="control-group">
                       <label htmlFor="fullname">Fullname</label>
                       <input type="text" className="control" placeholder='Enter your full name'/>
                    </div>

                    <div className="control-group">
                        <label htmlFor="email">Email Address</label>
                        <input type="text" className="control" placeholder='Enter your email address' />
                    </div>

                    <div className="control-group">
                      <label htmlFor="phone">Mobile Number</label>
                      <input type="text" className="control"  placeholder='Enter your mobile number'/>
                    </div>

                    <div className="control-group">
                      <label htmlFor="message">Message</label>
                       <textarea name="" id="message" cols="30" rows="5" className="control" placeholder='Message'></textarea>
                    </div>

                    <div className="bottom-container flex-center">
                       <button type="button" className="btn btn-primary"><span className="flex-center" style={{paddingRight:"10px"}}><FiSend/></span>Send Message</button>
                    </div>
                 </form>
            </div>
        </div>
    </section>
  )
}

export default Contact
import React,{useState} from 'react';
import "./header.css";
import {CgMenuGridR} from "react-icons/cg"
import {VscSettings} from "react-icons/vsc"
import { SettingsContext } from '../../context/Settings';
import {PriceSlider} from '../common'
import {useNavigate} from "react-router-dom"

function Header(props) {
  const [businessType,setBusinessType] = useState("sale");
  const redirect = useNavigate();

  const {SettingCores} = SettingsContext();
    return (
      <header id="main-header">

        <div className='main-container flex stretch-items'>

           <div className="column">
                  
                 {/*  <div className='heading'>
                    <h1 className="title">Search For Discounted Properties</h1>
                    <p>Find new & featured property located in your local city.</p>
                  </div> */}
     
                  <div className="contact-wrapper">
                  
                    <h1 className="title">Make A Request For Discounted Properties</h1>
                    <p className="text-muted">Hi..Drop a request and we will help find the best discounted properties deal for you.</p>
                
                    <div className='btn-container'>
                       <button className="btn" onClick={()=>redirect("/contact")}>Request Now</button>
                    </div>
                  </div>

           </div>


          <div className="column">

               <form action="">
                   <div className="top-wrapper flex-center">
                       <div className="wrapper flex gap10">
                         <button className={businessType === "sale" ? "btn btn-primary":"btn border"}  type="button" onClick={()=>setBusinessType("sale")}>Sale</button>
                         <button className={businessType === "rent" ? "btn btn-primary" :"btn border"} type="button" onClick={()=>setBusinessType("rent")}>Rent</button>  
                       </div> 
                   </div>  
   
                   <div className="wrapper">
                        <div className="row flex space-between gap10">
                            <div className="control-group">
                                <label htmlFor="category">Property Category</label>
                                <select name="" id="category" className="control">
                                   <option defaultValue="null"> Property Category</option>
                                   <option value="Land">Land</option>
                                   <option value="duplex">Duplex</option>
                                </select>
                            </div>
   
                            <div className="control-group">
                                <label htmlFor="city">City</label>
                                <input type="text" className="control" id='city' placeholder='City/Street' />
                            </div>
                        </div>
   
                        <div className="row flex space-between gap10">
   
                            <div className="control-group">
                                <label htmlFor="bed">Bedrooms</label>
                                <input type="number" className="control" placeholder='No. of bedrooms' />
                            </div>
   
                            <div className="control-group">
                                <label htmlFor="bed">Bathrooms</label>
                                <input type="number" className="control" placeholder='No. of bathrooms' />
                            </div>
                        </div>
   
                        <div className="flex space-between gap10">
                             <div className="price-wrapper">
                                 <PriceSlider/>
                             </div>
                             <div className="measure-wrapper">
   
                             </div>
                        </div>
   
                        <div className="bottom">
                             <button className="btn btn-primary" type='button' onClick={()=>redirect("/properties")}>Search</button>
                        </div>
                   </div>
   
               </form>

          </div>

        </div>




      </header>
    );
}

export default Header;
 import React,{useEffect} from 'react'
import "./home.css";
import {Navbar,Product,Footer,Header,SecondNav,PropertiesFilterBar, Overlay,Featured,Location} from "../../components";
import {LoadMoreBtn} from "../../components/common"
import {ApplicationContext} from "../../context/Application";
import { SettingsContext } from '../../context/Settings';


const Home = () => {


  //Get the home page products
  const {cores} = ApplicationContext();
  const {SettingCores} = SettingsContext();
  const {setActivePage} = SettingCores;

  useEffect(() => {
    
      setActivePage("home");
       window.scrollTo(0, 0)
    
  }, [])

  

  return (
    <div className='main_home_page'>
    {/* { SettingCores.showFilterBar && <Overlay styles={{zIndex:"10050",display:"block"}} events={()=>{SettingCores.setShowFilterBar(!SettingCores.showFilterBar)}}/>}
     <PropertiesFilterBar styles={{zIndex:"11000",background:"white",left:SettingCores.showFilterBar ? "0" : "-260px",boxShadow:SettingCores.showFilterBar ? "0.5rem 0 1rem rgba(1,1,1,0.5)" : "none", transition:"100ms ease-in" }}/> */}

       {/* <SecondNav/> */}
       <Header/>
       <Featured/>
       {/* <Location/> */}
      <main className='home-content-wrapper'>

        <section>
         <div className='heading'>
             <h1 className="title">Featured Properties</h1>
             <p>Find All Our Discounted Properties.</p>
          </div>
          <section className='product-wrapper'>
                  {cores.products.map((val) =>{
                    return <Product src={val.src} id={val.id} key={val.id} name={val.productName}/>
                  })}
               

          </section>

          <LoadMoreBtn/>
        </section>
          
      </main>
      <Footer/>
    </div>
  )
}

export default Home;













/* 
import React from "react"
import Awards from "./awards/Awards"
import Featured from "./featured/Featured"
import Hero from "./hero/Hero"
import Location from "./location/Location"
import Price from "./price/Price"
import Recent from "./recent/Recent"
import Team from "./team/Team"
import "./home.css";
import Header from "../../comp/common/header/Header"
import Footer from "../../comp/common/footer/Footer"




const Home = () => {
  return (
      <>
         <Header/>
         <Hero />
         <Featured />
         <Recent/>
         <Awards />
         <Location />
         <Team />
         <Price />
         <Footer/>
      </>
  )
}

export default Home */

import React from "react"
import "./Featured.css"
import FeaturedCard from "./FeaturedCard"

const Featured = () => {
  return (
    <>
      <section id="main-featured-category">

        <div className='container'>
          {/* <Heading title='Featured Property Types' subtitle='Find All Type of Property.' /> */}
          <div className='heading'>
             <h1 className="title">Featured Property Categories</h1>
             <p>Find All Type of Property.</p>
          </div>
          
          <FeaturedCard />
        </div>
      </section>
    </>
  )
}

export default Featured

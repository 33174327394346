export default {
    serverGetMethod:(url,dataToSend,callback) =>{
        let http = new XMLHttpRequest();
        let data =  dataToSend.length === 0 ? "" : dataToSend;
        http.open("GET",""+url+data);
        http.send();
        http.onreadystatechange = function(){
            
          if(http.status === 200 && http.readyState === 4 ){
            callback(http.responseText,null);
          }else{
            callback(null,"Error occured while processing data")
          }
          
        }
      },
  
}
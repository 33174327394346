import { createRoot } from 'react-dom/client';
import { Global } from "./context/Application";
import App from "./App"
import "./index.css";
import { Setting } from './context/Settings';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <Setting>
        <Global>
            <App/>
        </Global>
    </Setting>
);


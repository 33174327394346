import React,{useState} from 'react'
import { ApplicationContext } from '../../context/Application'
import "./css/navbar.css";
import {BsFilterCircle} from "react-icons/bs"
import {BsChevronDoubleDown} from "react-icons/bs"
import {FaUserTie} from "react-icons/fa"
import {AiOutlineSetting} from "react-icons/ai"
import {IoIosNotificationsOutline} from "react-icons/io"
import {Link} from "react-router-dom";
import {GiWorld} from "react-icons/gi"
import {CgMenuGridR} from "react-icons/cg"
import {VscSettings} from "react-icons/vsc"
import {FiMenu} from "react-icons/fi"
import {useNavigate} from "react-router-dom";
import { SettingsContext } from '../../context/Settings';
import {Overlay} from "../index"
import {FiLogIn} from "react-icons/fi";
import {FiUserPlus} from "react-icons/fi"
import {MdDashboard} from "react-icons/md"
import {FiLogOut} from "react-icons/fi"
import {AiOutlineProfile} from "react-icons/ai" 
import {AiOutlineFileAdd} from "react-icons/ai"
import {AiFillHome} from "react-icons/ai"
import {Storage} from "../../helpers/auth"
import {truncator} from "../../helpers/util"
import { useEffect } from 'react';



const Navbar = ({bottom = true}) => {

    const [open,setOpen] = useState(false);
    const [dropdown,setDropdown] = useState(false)
    const redirect = useNavigate()


    const {authUser,logout} = ApplicationContext();
    const {SettingCores} = SettingsContext();

    const {openSidebar, setOpenSidebar,activePage,setActivePage} = SettingCores;

  
    const name = authUser ? authUser.firstname : "";
    const profile = "";
    const statusText = "offline";
    const status = 0;
    


  return (
    <>
    <Overlay styles={{zIndex:"1100",display:dropdown ? "block" : "none"}} events={()=>{setDropdown(!dropdown)}}/>
    <nav  id="main-navigation-wrapper" className='flex space-between'>
       <div className="box flex-center space-between">
           <div className="logo-container">
              <h1>Tor<span className='color-primary'>bita</span></h1>
           </div>



           <span className="flex-center" onClick={()=>{setOpenSidebar(!openSidebar)}}>
             <FiMenu className="menu-icon"/>
           </span>
       </div>




       {/*  <div className="box flex-center">
            <div className="nav-buttons-container flex-center gap10">
                <div className="item flex-center icon-container dim-2rem">
                  <GiWorld/>
                </div>
                <div className="item flex-center icon-container dim-2rem">
                  <AiOutlineSetting/>
                </div>
                <div className="item flex-center icon-container dim-2rem">
                 <IoIosNotificationsOutline/>
                 <span className="count flex-center"></span>
               </div>
            </div>
        </div> */}


        <div className="box flex-center stretch-items gap25 nav-links-wrapper sidebar">
           <div className={activePage === "home" ?"item flex-center active" : "item flex-center"} onClick={()=>{setActivePage("home");redirect("/")}}>
              <Link to="/">Home</Link>
           </div>
           <div className={activePage === "agent" ?"item flex-center active" : "item flex-center"} onClick={()=>{setActivePage("agent");redirect("/agent")}}>
              <Link to="/">Agents</Link>
           </div>
           <div className={activePage === "listing" ?"item flex-center active" : "item flex-center"} onClick={()=>{setActivePage("listing");redirect("/properties")}}>
              <Link to="/properties">Listing</Link>
           </div>
           <div className= {activePage === "about" ?"item flex-center active" : "item flex-center"} onClick={()=>{setActivePage("about");redirect("/about")}}>
              <Link to="/">About</Link>
           </div>
           <div className={activePage === "contact" ?"item flex-center active" : "item flex-center"} onClick={()=>{setActivePage("contact");;redirect("/contact")}}>
              <Link to="/contact">Contact</Link>
           </div>
        </div>




       <div className={open === true ? "box flex gap15 sidebar show-sidebar":"box flex gap15 sidebar"}>
            <ul className="sign-buttons flex-center gap15">
               {authUser == null && <> <li><Link to="/create-account" className='sign btn create-btn'>Create Account</Link></li>
              <li><Link to="/login" className="sign btn btn-primary">Login</Link></li></> }
            </ul>




            {authUser !==null && <div className="user-info pointer">
              <div className="flex gap10" onClick={()=>{setDropdown(!dropdown)}}>
                <div className="profile-container">
                  { profile ? <img src={profile} alt={authUser.name}/> : <FaUserTie/>}
               </div>
               <div className="details">
                 
                  <h3 className="name">{Storage.getAuthUser() ? "Hi.."+truncator(name,10): "Hi.. Welcome"}</h3>
                  <p className={Storage.getAuthUser() ? "online color-success":""}>{Storage.getAuthUser() ? "Online" : <Link to ="/create-account">Join Us</Link>}</p>
               </div>
                <div className='transition300'>
                 <BsChevronDoubleDown className={dropdown ? 'transition300 rotate' : "transition300"}/>
                </div>
              </div>
               


                <section className={dropdown ? "dropdown show-dropdown":"dropdown"}>
                    <ul>
                      <li className='list flex' onClick={()=>{setDropdown(!dropdown);redirect("/")}}>
                        <Link className="flex-center flex-start gap10" to="/">
                          <AiFillHome/>
                          <h3>Home</h3>
                        </Link>
                      </li>
                      {Storage.getAuthUser() == null && <> <li className='list flex' onClick={()=>{setDropdown(!dropdown);redirect("/login")}}>
                        <Link className="flex-center flex-start gap10" to="/login">
                          <FiLogIn/>
                          <h3>Login</h3>
                        </Link>
                      </li>
                      <li className='list flex' onClick={()=>{setDropdown(!dropdown);redirect("/create-account")}}>
                        <Link className="flex-center flex-start gap10" to="/create-account">
                           <FiUserPlus/>
                           <h3>Register</h3>
                        </Link>
                      </li>
                       </>}

                      { Storage.getAuthUser() !== null && <><li className='list flex' onClick={()=>{setDropdown(!dropdown);redirect("/user/dashboard")}}>
                        <Link className="flex-center flex-start gap10" to="/user/dashboard">
                           <MdDashboard/>
                           <h3>Dashboard</h3>
                        </Link>
                      </li>
                      <li className='list flex' onClick={()=>{setDropdown(!dropdown);redirect("/user/profile")}}>
                        <Link className="flex-center flex-start gap10" to="/user/profile">
                           <AiOutlineProfile/>
                           <h3>Profile</h3>
                        </Link>
                      </li>
                      <li className='list flex' onClick={()=>{setDropdown(!dropdown);redirect("/upload-property")}}>
                         <Link className="flex-center flex-start gap10" to="/upload-property">
                             <AiOutlineFileAdd/>
                             <h3>Add Properties</h3>
                          </Link>
                      </li></>}
                      <li className='list flex' onClick={()=>{setDropdown(!dropdown);redirect("/setting")}}>
                        <Link className="flex-center flex-start gap10" to="/setting">
                           <AiOutlineSetting/>
                           <h3>Setting</h3>
                        </Link>
                      </li>
                      {Storage.getAuthUser() !== null && <li className='list flex' onClick={()=>{setDropdown(!dropdown);logout();redirect("/")}}>
                        <Link className="flex-center flex-start gap10" onClick={()=>{logout();redirect("/")}} to="">
                          <FiLogOut/>
                          <h3>Logout</h3>
                        </Link>
                      </li>}
                    

                    </ul>
                </section>
            </div>}


       </div>
    </nav>
    </>
  )
}

export default Navbar
import React from 'react'
import {BrowserRouter,Routes,Route} from "react-router-dom"
import {Home,Properties,Login, Register,Property, CreateAccount, ForgotPassword,Contact} from "./pages";
import { Navbar, Sidebar,Overlay } from './components';
import {Dashboard} from './user/index.js';
import "./App.css";

import { SettingsContext } from "./context/Settings"

function App(){
  
  const {SettingCores} = SettingsContext();
  const {openSidebar,setOpenSidebar} = SettingCores;

 

  return (
    <div className="homepage">
      
         <BrowserRouter>

         <Sidebar open={openSidebar}/>
         <Overlay styles={{background:"rgba(1,1,1,0.5)",zIndex:"200000", display: openSidebar ? "block" : "none"}} 
           events={()=>setOpenSidebar(!openSidebar)}/>
        
          <Navbar/>
             <Routes>
                 <Route path='/' element={<Home/>}/> 
                 <Route path='/properties' element={<Properties/>}/>
                 <Route path='/property/:id' element={<Property/>}/>
                 <Route path='/user'>
                    <Route path='/user/dashboard' element={<Dashboard/>}/>
                 </Route>
                 {/* <Route path="/about" element={<About/>}/>
                 <Route path="/pricing" element={<Pricing/>}/>
                 <Route path="/blog" element={<Blog/>}/>
                 <Route path="/services" element={<Services/>}/>*/}
                 <Route path="/contact" element={<Contact/>}/> 
                 <Route path="/upload-property" element=""/>
                 <Route path="/messages" element=""/>
                 <Route path="/create-account" element = { <CreateAccount/> }/>
                 <Route path="/register/:occupation" element={<Register/>}/>
                 <Route path="/login" element={<Login/>}/>
                 <Route path="/forgotpassword" element={ <ForgotPassword/>}/>
             </Routes>
         </BrowserRouter> 
        
         
    </div>
  )
}

export default App;
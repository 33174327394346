import React from 'react'
import { useNavigate } from 'react-router-dom';
import "./product.css";
import {FiCheck} from "react-icons/fi";
import {MdKingBed} from "react-icons/md";
import {MdOutlineBathtub} from "react-icons/md"
import {MdGarage} from "react-icons/md"
import {MdBarChart} from "react-icons/md"
import {GrLocation} from "react-icons/gr"




const Product = (props) => {
   
    const navigate = useNavigate();
    let handleClick = ()=>{
          navigate("/property/"+props.id);
    }
  return (
         <section id="main-product-card" className="transition300">
               <div className="top product-image" onClick={handleClick}>
                  <img src={props.src} alt={props.name}/>
                  {/* <span className="level">For Sale</span> */}
               </div>

                 
                 {/*  <p className="text-muted name"  style={{fontSize:"0.8rem"}}>Dr. Johnson brown <span className="count flex"><FiCheck/></span></p> */} 

               <div className="middle">
                  <h2 className="productName">{props.name ? props.name : ""}</h2>

                  <div className="type-age-wrapper flex-default">
                     <h3 className="type">Detached Duplex</h3>
                     <h3 className="dot-separator flex">.</h3>
                     <h3 className="age">3yrs</h3>
                  </div>   

                  <div className="advance-info-wrapper">
                       <h3 className="price">&#8358;5,000</h3>
                       <div className="location flex">
                          <span className="location-icon"><GrLocation/></span>
                          <span className="location-text">Plot 234 , Behind ,Maitama Abuja Nigeria</span>
                       </div>
                  </div>

                  <div className="basic-info-wrapper flex-default space-between radius-1">
                       <div className="basic-info flex-column">
                          <div className="flex-center">
                              <span className="info-icon"><MdKingBed/></span>
                              <span className="text-muted">2</span>
                          </div>
                          <span className="info-name">Bed</span>
                       </div>

                       <div className="basic-info flex-column">
                          <div className="flex-center">
                              <span className="info-icon"><MdGarage/></span>
                              <span className="text-muted">1</span>
                          </div>
                          <span className="info-name">Garage</span>
                       </div>

                       <div className="basic-info flex-column">
                          <div className="flex-center">
                              <span className="info-icon"><MdOutlineBathtub/></span>
                              <span className="text-muted">2</span>
                          </div>
                          <span className="info-name">Bath</span>
                       </div>

                     {/*   <div className="basic-info flex-column">
                          <div className="flex-center">
                              <span className="info-icon"><MdBarChart/></span>
                              <span className="text-muted">2</span>
                          </div>
                          <span className="info-name">Level</span>
                       </div> */}
                  </div>
                  <div className="rating-container flex">
                     <h3 className="total-star">4.5</h3>
                     <div className="star-container">
                       <span className="star">&#9733;</span>
                       <span className="star">&#9733;</span>
                       <span className="star">&#9733;</span>
                       <span className="star">&#9733;</span>
                       <span className="star">&#9733;</span>
                     </div>
                     <h3 className="total-rating"  style={{fontSize:"0.8rem"}}>(123,444)</h3>
                  </div>
               </div>
               {/* <div className="bottom">
                  <div className="btn-container">
                     <div className="btn btn-primary">Enquiry</div>
                  </div>
                  <div className="btn-container">
                     <div className="btn btn-primary">Wishlist</div>
                  </div>
               </div> */}
     </section>
  )
}

export default Product
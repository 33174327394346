import React,{useState,useEffect} from 'react'

import {Product ,Footer,PropertiesFilterBar,Overlay, PropertiesNav , GeneralRightBar} from "../../components/index"
import "./properties.css"
import {Img3, Img4, Img5, Img6, Img7, Img8, Img9, Img10, Img11} from "../../images/index.js";
import {BsFillGridFill} from "react-icons/bs"
import {FiFilter} from "react-icons/fi"
import {BsReverseLayoutTextSidebarReverse} from "react-icons/bs"
import {AiFillSetting} from "react-icons/ai"
import { SettingsContext } from '../../context/Settings';


const Properties = () => {
  const {Settings,SettingCores} = SettingsContext();
  const {setActivePage} = SettingCores;
  const [openFilter,setOpenFilter] = useState(false);

  useEffect(() => {
    
      setActivePage("listing");
       window.scrollTo(0, 0)
    
  }, [])
  
  return (
    <section id="properties-page-container">

      <Overlay  styles={{background:"rgba(1,1,1,0.4)",zIndex:"200000",display:openFilter ? "block":"none"}} events={()=>setOpenFilter(!openFilter)}/>

       <div>
         <PropertiesFilterBar className={openFilter ? "filter-bar open" : "filter-bar"}/> 
       </div>



       <main className='properties-page-content flex'>
        
         <section className="products-container">


              <div className="product-container-top flex">
                 <div className="left flex-center">
                   <h3>2455 Propertties</h3>
                 </div>

                 <div className="right flex-center first">
                   <h3 className='flex-center btn border'>
                     <FiFilter/> <span>Filter</span>
                   </h3>
                 </div>

                 <div className="right flex-center second">
                   <h3 className='flex-center btn border' onClick={()=>setOpenFilter(!openFilter)}>
                     <FiFilter/> <span>Filter</span>
                   </h3>
                 </div>


              </div>
              
              
             <div className="flex-default space-around wrap-flex wrapper">
              <Product src={Img3}/>  
              <Product src={Img4}/>  
              <Product src={Img5}/>  
              <Product src={Img6}/>   
              <Product src={Img7}/>  
              <Product src={Img8}/>  
             </div>
             <Footer/>
         </section>

       {/*  <section className="map-container">
            <GeneralRightBar/>
         </section>  */}
       </main>

      

      {/*  <div id="settings-button">
           <AiFillSetting/>
       </div> */}
    </section>
  )
}

export default Properties
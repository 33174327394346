import React, { useState,useRef,useEffect} from 'react';
import "./login.css";
import { useNavigate,Link } from 'react-router-dom';
import {Footer} from "../../components";

import {MdMarkEmailUnread} from "react-icons/md";
import {RiLockPasswordLine} from "react-icons/ri";
import {BiLogIn} from "react-icons/bi"
import { SettingsContext } from '../../context/Settings';
import { ApplicationContext } from '../../context/Application';
import {Storage} from "../../helpers/auth"
import Request from "../../helpers/request"


const Login = ()=>{
  
  const redirect = useNavigate();
 


    let navigate = useNavigate();
    const {ApplicationHelper,updateUser} = ApplicationContext();
    const {Settings,SettingCores} = SettingsContext();
    const {activePage,setActivePage} = SettingCores;


    useEffect(() => {
      if(Storage.getAuthUser()){
        redirect("/");
      }else{
        setActivePage("login");
         window.scrollTo(0, 0)
       } 
    }, [])
  
      //Creating References to input field
      let email = useRef();
      let password = useRef();
  
  
      //Placeholders Referencesc
      let emailPlaceholder = useRef();
      let passwordPlaceholder = useRef();
  
  
      let placeholderSwitch = (inputRef,placeholderRef)=>{
        if(inputRef.current.value.length !== 0){
          placeholderRef.current.style.top = "-13px";
          placeholderRef.current.style.color = "#396aff";
          placeholderRef.current.style.background = "#f1f1ff";
        }else{
          placeholderRef.current.style.top = "7px";
          placeholderRef.current.style.color = "#888";
          placeholderRef.current.style.background = "transparent";
        }
      }


      const validEmailRegex = RegExp(
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      );


      /* 
      ---------------------------------------------------------------------
                    VALIDATION START
      ---------------------------------------------------------------------
    */

    const [loginData,setSignupData] = useState({
      email:"",
      password:"",
    });

    const [errors,setErrors] =  useState({
        emailError:null,
        passwordError:null,
        generalError:null,
    });



    //Updating Signup Data
    let handleUpdate = (e) =>{
       setSignupData({...loginData,[e.target.name]:e.target.value})
    }


    let validEmail = () =>{
      if(validEmailRegex.test(loginData.email)){
        setErrors({...loginData,emailError:null});
        return true;
      }else{
        setErrors({...loginData,emailError:"Please enter a valid email address"});
        return false;
      }
    }



    let validator = (key) =>{
      if(key === "email"){
        loginData.email.trim() === "" ?  setErrors({...errors,emailError:"Please enter your email address"}) 
          : setErrors({...errors,emailError:null});
      }
      if(key === "password"){
          loginData.password.length === 0 ? setErrors({...errors,passwordError:"Please enter your password"}) 
          : setErrors({...errors,passwordError:null});
      }
    }

    
    let sendDataToServer = () =>{
      if(loginData.email.trim() !== ""){
        if(loginData.password.length >0){
          if(validEmail()){
            Request.serverGetMethod(Settings.host.PATH+"/loginuser.php","?data="+JSON.stringify(loginData),async(response,error)=>{
               if(response){
                response = JSON.parse(response);
                if(response.status === "success" && response.statusCode === 200){
                   if(response.data.userDetails){
                        updateUser(JSON.stringify(response.data.userDetails));
                        localStorage.setItem("authUser",JSON.stringify(response.data.userDetails)); 
                        navigate("/user/dashboard");
                   }
                }else{
                
                   setErrors({...errors, emailError:response.errors.emailError,
                                        passwordError:response.errors.passwordError,
                                        generalError:{confirmEmailError:response.errors.confirmEmailError}});
                }
               }

             })
            
          }
        }else{
          validator("password")
        }
      }else{
        validator("email")
      }
    }



    





  
  
    return (
        <div id='login-page'>
           
            <section className="sign-form-container">
            
                <form className="sign-form">
                   <h1 className="form-title">Sign In</h1>
                   <p className="text-muted">Don't have an account? <Link to="/create-account" className='link'>Register</Link></p>
                         {errors.generalError && errors.generalError.confirmEmailError && <div className='general-error'>
                                  {errors.generalError.confirmEmailError}
                                   <span className='instruction'>If you did not receive it, click on <Link to="/" onClick={()=>{sendDataToServer()}}>Resend</Link></span>
                                  </div> 
                                  
                              }
                               
                             
                          <div className="control-container flex-default space-between stretch-items">
                              <span className="form-icon-container flex-center transition200"><MdMarkEmailUnread/></span>

                              <label htmlFor="email" className="input-container">
                                 <input type="email" name='email' id="email" ref={email} 
                                  onKeyUp={(e)=>{handleUpdate(e);validator("email");placeholderSwitch(email,emailPlaceholder)}}  
                                  onClick={()=>{placeholderSwitch(email,emailPlaceholder)}}
                                  onChange={(e)=>{handleUpdate(e);validator("email");placeholderSwitch(email,emailPlaceholder)}}/>
                                  <span className="placeholder-text transition300" ref={emailPlaceholder}>Email Address</span>
                              </label>
                           </div>
                           <span className="form-error-block errors">{errors.emailError !== null && errors.emailError}</span>
                           
                    
                           <div className="control-container flex-default space-between stretch-items">
                               <span className="form-icon-container flex-center transition200"><RiLockPasswordLine/></span>
                               <label htmlFor="password" className="input-container">
                                  <input type="password" name='password' id="password" ref={password} 
                                  onChange={(e)=>{validator("password");handleUpdate(e);placeholderSwitch(password,passwordPlaceholder)}}
                                  onKeyUp={(e)=>{handleUpdate(e);validator("password");placeholderSwitch(password,passwordPlaceholder)}}  
                                  onClick={()=>{placeholderSwitch(password,passwordPlaceholder)}}/> 
                                  <span className="placeholder-text transition300" ref={passwordPlaceholder}>Password</span>
                                </label>                         
                           </div>
                           <span className="form-error-block errors">{errors.passwordError !== null && errors.passwordError}</span>
                           

                       <div className="bottom-container">
                          <div className='forgot'>  <Link to = "/forgotpassword">Forgot password</Link></div>
                          <div> <button type="button" onClick={()=>{sendDataToServer()}} className='btn btn-primary'> <span style={{paddingRight:"1rem"}} className="flex-center"><BiLogIn/> </span>Login</button></div>
                       </div>
                 
                </form>
            </section>

            
            <Footer/>
        </div>
      )
}


export default Login;